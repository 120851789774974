'use strict';

function calculateOffset(scrollTop, $el, $target) {
    var topOffset = $target.offset().top;
    var $parent = $el.parent();
    var $productSticky = $('#pdp-info-tab .js-sticky-pdp');
    var $productStickyLink = $productSticky.find('.js-sticky-link');
    var $tabContents = $('.product-detail .tab-contents');
    var diff = $tabContents.outerHeight(true) - $tabContents.height();

    if (scrollTop >= Math.floor(topOffset - diff) && scrollTop < Math.floor(topOffset - diff + $target.height())) {
        $productStickyLink.parent().removeClass('checked');
        $parent.addClass('checked');

        if($el.attr('href') == '#tpreviews' || $el.attr('href') == '#pdp-block-specifications') {
            $('.pdp-header-wrapper a[href$="#pdp-block-specifications"]').parent().addClass('checked');
            $('.pdp-header-wrapper a[href$="#tpreviews"]').parent().addClass('checked');
        }

        var $stickyTab = $('.pdp-header-wrapper a[href$="' + $el.attr('href') + '"]').parent();
        if (!$stickyTab.hasClass('checked')) $stickyTab.addClass('checked');
    }
}

function initStickyLinkPDP() {
    var $productSticky = $('#pdp-info-tab .js-sticky-pdp');
    var $productStickyLink = $productSticky.find('.js-sticky-link');
    const breakpoint = window.matchMedia('(max-width:991px)');

    if ($productSticky.length && !breakpoint.matches) {
        var isLinkClicked = false;

        $('.js-sticky-pdp').off('click', '.js-sticky-link').on('click', '.js-sticky-link', function (e) {
            e.preventDefault();
            var $el = $(this);
            var $target = $($el.attr('href'));
            var topOffset = $target.offset().top;
            var $tabContents = $('.product-detail .tab-contents');
            var diff = $tabContents.outerHeight(true) - $tabContents.height();
            var newPos = topOffset - diff;

            isLinkClicked = true;

            if ($(window).scrollTop() == newPos) {
                isLinkClicked = false;
                $el.parent().addClass('checked');
            }

            if ($target.length) {
                $('html, body').animate({
                    scrollTop: newPos
                }, 500, function() {
                    isLinkClicked = false;
                    calculateOffset($(window).scrollTop(), $el, $target);
                });
            }
        });

        $(window).scroll(function() {
            const breakpoint = window.matchMedia('(max-width:991px)');
		    if (!breakpoint.matches) {
                $productStickyLink.each(function () {
                    var $el = $(this);
                    var $target = $($el.attr('href'));
                    if ($target.length && !isLinkClicked) {
                        calculateOffset($(window).scrollTop(), $el, $target);
                    }
                });
            }
        });
    }
}

function initStickyHeaderPDP() {
    var pdpInfoTab = $('#pdp-info-tab');
    var jsStickyPdp = pdpInfoTab.find('.js-sticky-pdp');
    pdpInfoTab.css('height', $('.js-sticky-pdp').innerHeight());
    $(window).scroll(function() {
        const breakpoint = window.matchMedia('(max-width:991px)');
		if (!breakpoint.matches) {
            var pScrollTop = $(window).scrollTop();
            var heightHeader = $('.header').height();
            var diff = pdpInfoTab.offset().top - heightHeader - pScrollTop;
            if (pScrollTop >= pdpInfoTab.offset().top - heightHeader) {
                $('.header').css('top', diff);
                $('.trust-banner-carousel-container').removeClass('visible');
                if (pScrollTop >= pdpInfoTab.offset().top) {
                    $('.pdp-header-wrapper').addClass('no-index');
                    jsStickyPdp.addClass('active');
                } else {
                    $('.pdp-header-wrapper').removeClass('no-index');
                    jsStickyPdp.removeClass('active');
                }
            } else {
                $('.trust-banner-carousel-container').addClass('visible');
                $('.header').css('top', 0);
                $('.pdp-header-wrapper').removeClass('no-index');
                jsStickyPdp.removeClass('active');
            }
        } else {
            $('.trust-banner-carousel-container').addClass('visible');
            $('.header').css('top', 0);
            $('.pdp-header-wrapper').removeClass('no-index');
            jsStickyPdp.removeClass('active');
        }
    });
}

function toggleReviews() {
    const trustpilotWidget = document.querySelector('#tpreviews .trustpilot-widget');
    if (trustpilotWidget) {
        if (trustpilotWidget.querySelector("iframe") && trustpilotWidget.querySelector("iframe").style.display === 'block'){
            document.getElementById('tpreviews').parentElement.style.display = 'block';
            $('.tab-tpreviews').show();
        }

        const observer = new MutationObserver(function (mutations, observer) {
            for (let mutation of mutations) {
                if ((mutation.type === 'attributes' || mutation.type === 'childList') && mutation.target.closest('#pdpAccordion-collapseReviews') && trustpilotWidget.querySelector("iframe")) {
                    if (trustpilotWidget.querySelector("iframe").style.display === 'block') {
                        document.getElementById('tpreviews').parentElement.style.display = 'block';
                        $('.tab-tpreviews').show();
                    } else {
                        document.getElementById('tpreviews').parentElement.style.display = 'none';
                        $('.tab-tpreviews').hide();
                        observer.disconnect();
                    }
                }
            }
        });
        observer.observe(trustpilotWidget, {childList: true, subtree: true, attributeFilter: ["style"]});
    }
}

module.exports = {
    initStickyHeaderPDP: initStickyHeaderPDP,
    initStickyLinkPDP: initStickyLinkPDP,
    toggleReviews: toggleReviews
};

$(window).on('resize', function() {
    initStickyHeaderPDP();
    initStickyLinkPDP();
    toggleReviews();
});