let widthOfMenu = 0;
let lastScrollY = 0;
let direction = null;
let elmHeader = $('.header');
let enableScroll = true;

function updateHeaderBubbles() {
	// hide header heart and cart red bubble if count is 0
	function updateBubble($el) {
		if (parseInt($el.html()) == 0) {
			setTimeout(function() {
				$el.removeClass('show');
			}, 800);
		} else {
			setTimeout(function() {
				$el.addClass('show');
			}, 200);
		}
	}
	updateBubble($('.minicart-quantity'));
	updateBubble($('.miniheart-quantity'));
}

function addPageHeaderMargin() {
	$('div#maincontent:not(.checkout-flow)').css('margin-top', $('header').height() - $('.header-promotion-top').height());
	$('.header .header-container .dropdown-menu-container').css('max-height', 'calc(100vh - ' + $('header').height() + 'px)');
}

function replaceCharacter(string, index, replacement) {
	const arr = string.split('');
	arr[index] = replacement;
	return arr.join('');
}

function renderMenuPlaceholder() {
	const menuPlaceholder = $('body').append('<div class="menu-placeholder"></div>').find('.menu-placeholder');
	const $menuItems = $('.categories-menu h2.nav-link');

	$menuItems.each(function () {
		const $el = $(this);
		const text = $el.text();
		const indexSpace = text.lastIndexOf(' ');
		const newText = replaceCharacter(text, indexSpace, ' <br/>');

		$el.html(newText);
		menuPlaceholder.append('<h2>' + text + '</h2>');
	});

	menuPlaceholder.children('h2').each(function() {
		widthOfMenu += $(this).outerWidth();
	});
}

function calcMainMenu() {
	const $headerBottom = $('.header-container .menu-group-bottom');
	let headerBottomWidth = $headerBottom.width();
	let headerIconLinksWidth= $headerBottom.find('.header-icon-links').outerWidth();

	if ((headerBottomWidth - headerIconLinksWidth - 5) < widthOfMenu) {
		$headerBottom.addClass('breakline');
	} else {
		$headerBottom.removeClass('breakline');
	}
}

// Get direction of scroll event
function getDirection() {
    const scrollY = $(document).scrollTop();
    direction = scrollY > lastScrollY ? 'down' : scrollY < lastScrollY ? 'up' : '-';
    lastScrollY = scrollY;
};

/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
 function isMobileSearchHeader(scope) {
    return !!$(scope).closest('.header-mobile-container').length;
}

function updateTopDisplayCartErrorMessage () {
	var mainHeaderHeight = $('.header').innerHeight();
	var alertDangerHeight = $('#disabledProceedBtnMsg').innerHeight();
	var errorMessageHeight = alertDangerHeight + 16 +'px';
	$('#disabledProceedBtnMsg').css('top', mainHeaderHeight);
	$('.trust-banner-carousel-container').css('top', mainHeaderHeight);
	$('.hero .hero-breadcrumb-up').css('margin-top', errorMessageHeight);
}

function updateScrollMarginTrustpilot () {
	const breakpoint = window.matchMedia('(max-width:991px)');
	if (breakpoint.matches) {
		$('.custom-scroll-margin').css('scroll-margin-top', 80 + $('.header-promotion-top').height());
	} else {
		$('.custom-scroll-margin').css('scroll-margin-top', 243);
	}
}

module.exports = function() {
	renderMenuPlaceholder();
	calcMainMenu();
	// Add page margin for fixed header on mobile vs desktop devices
	addPageHeaderMargin();
	updateScrollMarginTrustpilot();
	$(window).resize(function() {
		addPageHeaderMargin();
		calcMainMenu();
		updateScrollMarginTrustpilot();
	});

	// Init header dropdown menus
	$('.header .menu-group-bottom > ul > li.nav-item > h2.nav-link').on({
		click: function() {
			const $selectedMenuItem = $(this);
			$selectedMenuItem.attr('data-hovered', true);
			$(this).find('.dropdown-menu-container').addClass('show');
			$(this).find('.nav-link').addClass('showing');
			if ($selectedMenuItem.attr('data-hovered') === 'true') {
				// open dropdown
				const currentlyOpen = $('.menu-group-bottom').find('.dropdown-menu-container.show');
				const currentlyOpenLinks = $('.menu-group-bottom').find('.nav-link.showing');
				setTimeout(() => {
					currentlyOpen.removeClass('show');
					currentlyOpenLinks.removeClass('showing');
				}, 75);
				if ($selectedMenuItem.attr('id') && $selectedMenuItem.attr('id').length > 0) {
					const selectedId = $selectedMenuItem.attr('id').substring(9);
					const dropdownId = '#dropdown-menu-'.concat(selectedId);
					$(dropdownId).addClass('show');
					$selectedMenuItem.addClass('showing'); // add active class on link
					setTimeout(function() {
						$('#suggestions-wrapper-mini').hide();
					}, 300);
				}
			}

			$selectedMenuItem.attr('data-hovered', false);
		},

	});

	$('.header').on({
		mouseleave: function() {
			setTimeout(() => {
				$('.menu-group-bottom')
					.find('.dropdown-menu-container.show')
					.removeClass('show');
				$('.menu-group-bottom')
					.find('.nav-link.showing')
					.removeClass('showing');
			}, 150);
		},
	});

	// Update cart and wishlist bubbles
	updateHeaderBubbles();
	$('.minicart-quantity, .miniheart-quantity').bind('DOMSubtreeModified', updateHeaderBubbles); // on ajax change

	$('body').on('click', function(e) {
		if ($(e.target).parent().hasClass('nav-item-search') || $(e.target).hasClass('search-field-action')) {
			e.preventDefault();
			if(isMobileSearchHeader(e.target)) {
				$('.modal-background').show();
				$('.modal-background').addClass('search-mobile');
			}
			elmHeader.addClass('header-has-search');

			if($(e.target).hasClass('search-field-action')) {
				enableScroll = false;
			}

			setTimeout(function() {
				enableScroll = true;
			}, 300);
			updateTopDisplayCartErrorMessage();
		} else {
			setTimeout(function() {
				if($(e.target).closest('.categories-menu').length && elmHeader.hasClass('header-has-search')) {
					elmHeader.removeClass('header-has-search');
					elmHeader.removeClass('header-down');
				} else {
					if(!$('.suggestions-mini').length || $('.suggestions-mini').css('display') === 'none') {
						elmHeader.removeClass('header-has-search');
						$('.modal-background').hide();
						$('.modal-background').removeClass('search-mobile');
					}
				}
				updateTopDisplayCartErrorMessage();
			}, 100);
		}
	});

	window.addEventListener('scroll', function() {
		getDirection();
		updateTopDisplayCartErrorMessage();
		if(!$('.suggestions-mini').length || !$('.suggestions-mini').height() || $('.suggestions-mini').css('display') === 'none') {
			if(enableScroll) {
				elmHeader.removeClass('header-has-search');
				$('.modal-background').hide();
				$('.modal-background').removeClass('search-mobile');
			}
		}
        if(direction === 'down' && window.scrollY >= elmHeader.innerHeight()) {
			// scroll down
			if(!elmHeader.hasClass('header-down')) {
				elmHeader.addClass('header-down');
			}
		} else {
			// scroll up 
			elmHeader.removeClass('header-down');
		}
	});
};
