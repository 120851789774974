const toastMessage = require('../components/toast-message');
const checkoutBase = require('../checkout/gtmCheckout');
const cartEvent = require('./viewCartEvent');
const cartBeginCheckoutEvent = require('./cartBeginCheckout');
const shippingInfoEvent = require('./addShippingInfoEvent');
const {isGTMEnabled} = require('../components/helpers');
const shippingAddressHelper = require('../components/shippingAddressHelper');
var amartCartGoogleCount = 0;

function appendToUrl(url, params) {
	let newUrl = url;
	newUrl +=
		(newUrl.indexOf('?') !== -1 ? '&' : '?') +
		Object.keys(params)
			.map(function(key) {
				return `${key}=${encodeURIComponent(params[key])}`;
			})
			.join('&');

	return newUrl;
}

/**
 * Creates JS DOM Object based on HTML address string
 * @param {string} address Google Maps address html string
 * @returns {Object} DOM object
 */
function preProcessGoogleAddress(address) {
	const prefix = address.substring(0, address.indexOf('<span'));

	if (!prefix) {
		return $(address);
	}
	const $address = $(address.substring(address.indexOf('<span')));
	$address.eq(0).html(prefix + $address.eq(0).html());

	return $address;
}

function getPostcodeByLat(latlng, callback) {
	const geocoder = new google.maps.Geocoder();
	var postcode = '';
	geocoder.geocode({'location': latlng}, function(results, status) {
		if (status === 'OK') {
			if (results[0]) {
				var addressParts = results[0].address_components;

				for (var i = 0; i < addressParts.length; i++) {
					var addressType = addressParts[i].types[0];
					if (addressType == "postal_code") {
						callback({postcode : addressParts[i].long_name});
						break;
					}
				}
			} else {
				console.log('No results found');
			}
		} else {
			console.log('Geocoder failed due to: ' + status);
		}
  	});
	callback({postcode : ''});
}

function parseAutocompleteAddress(place) {
	if (!place || !place.adr_address) return null;
	const $parsed = preProcessGoogleAddress(place.adr_address);
	const result = {
		address1: $parsed.filter('.street-address').text(),
		city: $parsed.filter('.locality').text(),
		stateCode: $parsed.filter('.region').text(),
		postalCode: $parsed.filter('.postal-code').text(),
	};

	return result;
}

function setUpDeliveryEstimateAutocomplete() {
	const elEstimateInput = document.querySelector('#estimate-input');
	const elEstimateBtn = document.querySelector('.delivery-estimate-btn');

	if (!elEstimateInput) {
		console.warn('Delivery estimate field not found, cannot set up autocomplete.');
		return;
	}

	if (typeof google === 'undefined' && amartCartGoogleCount < 1) {
		amartCartGoogleCount += 1;
		setTimeout(setUpDeliveryEstimateAutocomplete, 3000);
		return;
	}

	if (typeof google === 'undefined') {
		return;
	}

	var defaultBounds = new google.maps.LatLngBounds(
		new google.maps.LatLng(-33.8902, 151.1759),
		new google.maps.LatLng(-33.8474, 151.2631));
	var options = {bounds: defaultBounds};

	const autocomplete = new window.google.maps.places.Autocomplete(elEstimateInput, options);

	autocomplete.setComponentRestrictions({country: ['au']});
	autocomplete.setFields(['adr_address', 'geometry']);
	autocomplete.setTypes(['address']);

	autocomplete.addListener('place_changed', function() {
		const place = autocomplete.getPlace();
		const addrObject = parseAutocompleteAddress(place);
		var geometry;
		var latlng;
		shippingAddressHelper.validateDeliveryAddress($('#estimate-input').val(), 'cart');
		if (addrObject) {
			if (!addrObject.postalCode) {
				geometry = place.geometry;
				latlng = {lat: parseFloat(geometry.location.lat()), lng: parseFloat(geometry.location.lng())};
				getPostcodeByLat(latlng, function(data){
					addrObject.postalCode = data.postcode;
					elEstimateInput.dataset.result = JSON.stringify(addrObject);
					// Changed for OE without store add to cart- START
					elEstimateInput.dataset.locationObject = latlng ? JSON.stringify(latlng) : '';
					// Changed for OE without store add to cart- END
					elEstimateBtn.removeAttribute('disabled');
					$('.storelocator-searchbox').removeClass('notify_required');
				});
			} else {
				// Changed for OE without store add to cart- START
				geometry = place.geometry;
				latlng = {lat: parseFloat(geometry.location.lat()), lng: parseFloat(geometry.location.lng())};
				elEstimateInput.dataset.locationObject = latlng ? JSON.stringify(latlng) : '';
				// Changed for OE without store add to cart- END
				elEstimateInput.dataset.result = JSON.stringify(addrObject);
				elEstimateBtn.removeAttribute('disabled');
				$('.storelocator-searchbox').removeClass('notify_required');
			}
		} else {
			elEstimateInput.dataset.result = '';
			elEstimateBtn.setAttribute('disabled');
		}
	});
}

function setUpDeliveryEstimateSubmit() {
	/*
	$('.delivery-estimate-form').submit(function(event) {
		event.preventDefault();
		const url = this.getAttribute('action');
		const elEstimateInput = this.querySelector('#estimate-input');
		const $estimateResultValue = $('.estimate-result-value');
		const $estimateResultWrapper = $('.estimate-result-wrapper');
		const $estimateErrorWrapper = $('.estimate-result-wrapper-error');
		if (elEstimateInput && elEstimateInput.dataset.result) {
			$.spinner().start();
			$.post(url, {addressObject: elEstimateInput.dataset.result}, function(data) {
				$.spinner().stop();
				if(data.cost > 0){
					$estimateResultValue.html(data.formattedCost);
					$estimateResultWrapper.removeClass('d-none');
					$estimateErrorWrapper.addClass('d-none');
				}else{
					$estimateResultWrapper.addClass('d-none');
					$estimateErrorWrapper.removeClass('d-none');
				}
			});
		}
	});
	*/

	$('.delivery-estimate-form').submit(function(event) {
		event.preventDefault();
		$("#estimate-input").removeClass('notify_required');
		const url = this.getAttribute('action');
		const elEstimateInput = this.querySelector('#estimate-input');
		if (elEstimateInput && elEstimateInput.dataset.result) {
			$.spinner().start();

			var storeSearchValue = $('input.estimate-input').val();
			sessionStorage.setItem("storeSearchValue", storeSearchValue);
			// Changed for OE without store add to cart- START
			$('.delivery-estimate-results').load(url, {addressObject: elEstimateInput.dataset.result,locationObject: elEstimateInput.dataset.locationObject}, function() {
			// Changed for OE without store add to cart- START
				$("#addressConfirmBTN").remove();
				$.spinner().stop();
				$('body').addClass('modal-open');
				if($('#atl').length < 1 && $('.delivery-options-agreement').length < 1 && $('.carousel-item.active').length > 0){
					$("#deliveryConfirmBTN").prop('disabled',false);
					$("#deliveryConfirmBTN").removeClass('disabled');
				}

				if (document.getElementById('atl')) {
					if (document.getElementById('atl').checked === true && $('.delivery-options-agreement').length === 0) {
						if (document.getElementById('atlInvalidMessage')) {
							document.getElementById('atlInvalidMessage').style.display = 'none';
						}
						if (document.getElementById('deliveryConfirmBTN')) {
							document.getElementById('deliveryConfirmBTN').removeAttribute('disabled');
						}
						if (document.getElementById('deliveryConfirmBTN')) {
							document.getElementById('deliveryConfirmBTN').classList.remove('disabled');
						}
					}
				}

				/*
				if(data.cost > 0){
					$estimateResultValue.html(data.formattedCost);
					$estimateResultWrapper.removeClass('d-none');
					$estimateErrorWrapper.addClass('d-none');
				}else{
					$estimateResultWrapper.addClass('d-none');
					$estimateErrorWrapper.removeClass('d-none');
				}
				*/
			});
		}
	});
}

function updateGtmViewCart() {
	const datalayerElem = window.dataLayer.find(element => element.event === 'basketUpdated');
	if (datalayerElem) {
		datalayerElem.event = 'viewCart';
	}

	checkoutBase.stepTracking('cart');
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
 function confirmDelete(actionUrl, productID, productName, uuid, quantity) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-remove');
	$('.approaching-discounts').css('display', 'none');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);
	$deleteConfirmBtn.attr('data-quantity', +quantity);

    $productToRemoveSpan.empty().append(productName);
}

/**
 * To check referrer link for back to shopping
 */
function cartBacktoShipping() {
	var defaultUrl = $('.hero.cart-header .hero-breadcrumb-up').attr('href');
    var previousUrl = document.referrer ? document.referrer : defaultUrl;
	var currentUrl = window.location.href;
	var redirectUrl;
	if (currentUrl === document.referrer) {
		redirectUrl = localStorage.getItem('referrelUrl') ? localStorage.getItem('referrelUrl') : defaultUrl;
		$('a.hero-breadcrumb-up').attr("href", redirectUrl);
	} else {
		$('a.hero-breadcrumb-up').attr("href", previousUrl);
	}
}

/**
 * To make error message in cart as sticky
 */
function displayCartErrorMessage () {
	if ($('#disabledProceedBtnMsg') && $('#disabledProceedBtnMsg').length > 0 ) {
		const $deliveryOption = $('.js-delivery-option');
		if ($deliveryOption.hasClass('active')) {
			$('#disabledProceedBtnMsg').find('.delivery-proceed-error').addClass('d-none');
			$('#disabledProceedBtnMsg').find('.checkout-proceed-error').removeClass('d-none');
		}
		var alertDangerHeight = $('#disabledProceedBtnMsg').innerHeight();
		var errorMessageHeight = alertDangerHeight + 16 +'px';

		if (window.innerWidth > 575) {
			$('.cart-error-messaging.cart-error').css('margin-bottom', errorMessageHeight);
			$('.hero .hero-breadcrumb-up').css('margin-top', errorMessageHeight);
			$('#disabledProceedBtnMsg').css('top', '');

			if ($(".cart-error-messaging .alert-info") && $(".cart-error-messaging .alert-info").length > 0 ) {
				$(".cart-error-messaging .alert-info").css('top', errorMessageHeight);
				$('#disabledProceedBtnMsg').css('margin-top', '-' + errorMessageHeight);
				$('.hero .hero-breadcrumb-up').css('margin-top', '');
			} else {
				$(".cart-error-messaging .alert-info").css('top', '');
				$('#disabledProceedBtnMsg').css('margin-top', '');
			}
		} else {
			var mainHeaderHeight = $('.header').innerHeight();
			var mobileHeaderHeight = $('.header-mobile-container').innerHeight();

			$(".cart-error-messaging .alert-info").css('top', mobileHeaderHeight);
			$('.cart-error-messaging.cart-error').css('margin-bottom', '');
			$('#disabledProceedBtnMsg').css('margin-top', '');
			$('#disabledProceedBtnMsg').css('top', mainHeaderHeight);
			$('.hero .hero-breadcrumb-up').css('margin-top', errorMessageHeight);
		}
	}
}

function setPositionPac() {
	var pos = $(".estimate-input").offset().top + $(".estimate-input").innerHeight();
	$('.pac-container').css('top', Math.floor(pos));
	$('.delivery-estimate-form .custom-tooltip').css('position','relative');
	$('.delivery-estimate-form .custom-tooltip').addClass('custom-tooltip-mb');
}

function handleItemClose(itemId, $this) {
	const cookies = document.cookie.split(';');

	const existingCookie = cookies.find(cookie => cookie.trim().startsWith('hiddenItems='));
	let hiddenItems = [];

	if (existingCookie) {
	  	hiddenItems = existingCookie.split('=')[1].split(',').map(item => item.trim());
	}


	if (!hiddenItems.includes(itemId)) {
		$this.closest('[id="'+itemId+'"]').hide();
		hiddenItems.push(itemId);
		const now = new Date();
		now.setTime(now.getTime() + 1 * 24 * 60 * 60 * 1000);
		document.cookie = `hiddenItems=${hiddenItems.join(',')}; expires=${now.toUTCString()}; path=/`;
	}
}

function checkHiddenItems() {
	const cookies = document.cookie.split(';');
	const existingCookie = cookies.find(cookie => cookie.trim().startsWith('hiddenItems='));

	if (existingCookie) {
	  const hiddenItems = existingCookie.split('=')[1].split(',').map(item => item.trim());
	  const elements = document.getElementsByClassName('group-smart-care');
	  for (const element of elements) {
		const itemId = element.id;

		if (!hiddenItems.includes(itemId)) {
			element.style.display = 'block';
		} else {
			element.style.display = 'none';
		}
	  }
	} else {
		const elements = document.getElementsByClassName('group-smart-care');
		for (const element of elements) {
			element.style.display = 'block';
		}
	}
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
 function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts && approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (approachingDiscount) {
            if (approachingDiscount.showApproachingPromotionMsg === true) {
                html += '<div class="approaching-discounts-inner">';
                html += '<div class="single-approaching-discount text-center">';
                html += '<span class="single-approaching-discount-message">' + approachingDiscount.discountMsg + '</span>';
                html += '<div class="group-progress">';
                html += '<p class="progress" data-conditionthreshold="' + approachingDiscount.conditionThreshold + '" data-merchandisetotal="' + approachingDiscount.merchandiseTotal + '" data-distancefromconditionthreshold="' + approachingDiscount.distanceFromConditionThreshold + '">';
                html += '<span class="progress-bar" style="width: 100%;"></span>';
                html += '<span class="icon"><span class="fa fa-check"></span></span>';
                html += '</p>';
                html += '</div>';
                html += '<span class="single-approaching-discount-disclaimer">' +approachingDiscount.disclaimer+ '</span>';
                html += '</div>';
                html += '</div>';
            }
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
 function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    data.items.forEach(function (item) {
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        }
        if (item.priceTotal && item.priceTotal.renderedPrice) {
            $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
        }
    });
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
 function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' + data.valid.message + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append('<div class="row"> ' +
                '<div class="col-12 text-center"> ' +
                '<h1>' + data.resources.emptyCartMsg + '</h1> ' +
                '</div> ' +
                '</div>'
            );
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
 function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' + message + '</div>';

    $('.cart-error').append(errorHtml);
}

function updateShipmentsPrice(data) {
    if(data.totals.shipmentsPrice && data.totals.shipmentsPrice.length) {
        for (var i = 0; i < data.totals.shipmentsPrice.length; i++) {
            var shippingPrice = data.totals.shipmentsPrice[i];
            if(shippingPrice.discount.value !== 0 && shippingPrice.totalShippingCostForShipment.value !== shippingPrice.discount.value) {
                var $shippingInfo =  $('.row-shipment-info[data-shipping-method="'+shippingPrice.shippingMethodID+'"]');
                if ($shippingInfo.length) {
                    $shippingInfo.toggleClass('custom-price-discount', true);
                    $shippingInfo.find('.custom-price-sales .text-right').replaceWith('<div class="text-right shipping-discount">'+ shippingPrice.totalIncludingShippingDiscount.formatted +'</div>');
                    var $customPriceSales = $shippingInfo.find('.custom-price-sales .strike-through');
                    if($customPriceSales.length) {
                        $customPriceSales.text(shippingPrice.totalShippingCostForShipment.formatted);
                    } else {
                        $shippingInfo.find('.custom-price-sales').prepend('<p class="strike-through">' + shippingPrice.totalShippingCostForShipment.formatted + '</p>');
                    }

                    var $shippingInfoLeft = $shippingInfo.find('.shipment-info-left');
                    if($shippingInfoLeft.length && shippingPrice.discount.value > 0) {
                        var $shipmentSaving = $shippingInfoLeft.find('.shipment-ttl-saving');
                        var shipmentSavingHTML = 'Saving<span class="price-saving">' + shippingPrice.discount.formatted + '</span>';
                        if(!$shipmentSaving.length) {
                            $shippingInfoLeft.append('<div><span class="shipment-ttl shipment-ttl-saving">' + shipmentSavingHTML + '</span></div>');
                        } else {
                            $shipmentSaving.html(shipmentSavingHTML);
                        }
                    }
                }
            } else if(shippingPrice.discount.value === 0) {
                var $shippingInfo =  $('.row-shipment-info[data-shipping-method="'+shippingPrice.shippingMethodID+'"]');
                if ($shippingInfo.length) {
                    $shippingInfo.toggleClass('custom-price-discount', false);
                    var $customPriceSales = $shippingInfo.find('.custom-price-sales .strike-through');
                    if($customPriceSales.length && shippingPrice.totalShippingCostForShipment.value > 0) {
                        $shippingInfo.find('.custom-price-sales .text-right.shipping-discount').remove();
                        $customPriceSales.replaceWith('<p class="text-right">' + shippingPrice.totalShippingCostForShipment.formatted + '</p>');
                    }

                    var $shipmentSaving = $shippingInfo.find('.shipment-info-left .shipment-ttl-saving');
                    if($shipmentSaving.length) {
                        $shipmentSaving.empty();
                    }
                }
            }
        }
    }
}

module.exports = function() {
	setUpDeliveryEstimateAutocomplete();
	setUpDeliveryEstimateSubmit();
	cartBacktoShipping();
	cartEvent.viewCartEvent();
	cartBeginCheckoutEvent.cartBeginCheckout();
	shippingInfoEvent.addShippingInfoEvent();

	$('[id^=select-delivery]').change(function(e) {
		const url = $(this).data('url');
		$.spinner().start();
		window.location.href = `${url}&method=${e.target.value}`;
	});
	updateGtmViewCart();
	const errorItems = $('.card.product-info.error');
	if (errorItems.length) {
		$('.checkout-btn').addClass('disabled');
	}

	var currStoreSearch = sessionStorage.getItem('storeSearchValue');
	if (!!currStoreSearch) {
		$('input.estimate-input').val(currStoreSearch);
	}

	$(document).on('click', '#addressConfirmBTN', function() {
		if($(".delivery-estimate-btn").prop('disabled')){
			$("#estimate-input").addClass('notify_required');
		}else{
			$(".delivery-estimate-btn").trigger('click');
		}
	});

	$('.quantity-form > .amart-quantity').change(function() {
		const preSelectQty = $(this).data('pre-select-qty');
		const quantity = $(this).val();
		const $productID = $(this).data('pid');
		let url = $(this).data('action');
		const uuid = $(this).data('uuid');
		const $cartAction =
			parseFloat(quantity).toFixed(1) > parseInt(preSelectQty, 10) ? 'addToCart' : 'removeFromCart';
		const $cartEvent = parseFloat(quantity).toFixed(1) > parseInt(preSelectQty, 10) ? 'add' : 'remove';
		const $changedQty =
			parseFloat(quantity).toFixed(1) > parseInt(preSelectQty, 10)
				? parseFloat(quantity).toFixed(1) - parseInt(preSelectQty, 10)
				: parseInt(preSelectQty, 10) - parseFloat(quantity).toFixed(1);
		const $price = $(this).data('product-price');
		const $productName = $(this).data('product-name');

		const urlParams = {
			pid: $productID,
			quantity: quantity,
			uuid: uuid,
		};
		url = appendToUrl(url, urlParams);

		$.spinner().start();
        $('.cart-totals .custom-price-sales .text-right, .strike-through, .shipment-info-left .shipment-ttl-saving, .custom-price .shipping-cost, .custom-price .shipping-discount').toggleClass('d-none', true);
        $('.cart-totals .custom-price .loading-icon').toggleClass('d-none', false);

		$.ajax({
			url: url,
			type: 'get',
			context: this,
			dataType: 'json',
			success: function(data) {
				if (isGTMEnabled && window.dataLayer) {
					const gtmCartEvent = {
						event: $cartAction,
						ecommerce: {},
					};
					gtmCartEvent.ecommerce[$cartEvent] = {};
					const products = {};
					products.id = $productID;
					products.name = $productName;
					products.price = $price;
					products.quantity = $changedQty;
					gtmCartEvent.ecommerce[$cartEvent].products = [];
					gtmCartEvent.ecommerce[$cartEvent].products.push(products);
					localStorage.removeItem('gtm-cart-update-values');
					localStorage.setItem('gtm-cart-update-values', JSON.stringify(gtmCartEvent));
				}
				window.location.reload();
			},
			error: function(err) {
				if (err.responseJSON.redirectUrl) {
					window.location.href = err.responseJSON.redirectUrl;
				} else {
					toastMessage.error(err.responseJSON.errorMessage);
					$(this).val(parseInt(preSelectQty, 10));
					$.spinner().stop();
                    $('.cart-totals .custom-price-sales .text-right, .strike-through, .shipment-info-left .shipment-ttl-saving, .custom-price .shipping-cost, .custom-price .shipping-discount').toggleClass('d-none', false);
                    $('.cart-totals .custom-price .loading-icon').toggleClass('d-none', true);
				}
			},
		});
	});

	$(document).on('click', '.cart-delivery-popup .js-rb-delivery', function(event) {
		const shippingType = $(this).val();
		const carouselId = `#carousel${shippingType}`;
		const $deliveryOption = $(this).closest('.delivery-option');
		if ($(this).is(':checked') && !$deliveryOption.hasClass('selected')) {
			$('.cart-delivery-popup .delivery-option-fees-container').addClass('d-none');
			$(this).closest('.delivery-options').find('.delivery-option.selected').removeClass('selected');
			$deliveryOption.addClass('selected');
			$(carouselId).closest('.delivery-option-fees-container').removeClass('d-none');
		}
	});

	$(document).on('click', '#store-location-button', function(e) {
		if (!navigator.geolocation) {
			return;
		}
		navigator.geolocation.getCurrentPosition(function(position) {
			const geocoder = new google.maps.Geocoder();
			const latlng = {
			  lat: parseFloat(position.coords.latitude),
			  lng: parseFloat(position.coords.longitude),
			};
			geocoder.geocode({ location: latlng }, (results, status) => {
			  if (status === "OK") {
				if (results[0]) {
				  console.log(results[0]);
				} else {
				  console.log("No results found");
				}
			  } else {
				console.log("Geocoder failed due to: " + status);
			  }
			});
		});
	});

	$(document).on('click', '#cart-checkout-button a.disabled', function(e) {
		e.preventDefault();
		$('#disabledProceedBtnMsg').removeClass('d-none').addClass('show');

		displayCartErrorMessage();

		const $deliveryOption = $('.js-delivery-option');

		if (($('[id^="delivery-selector-delivery-"]:checked').length || $('.product-info-delivery .cart-online-exclusive').length) && !$deliveryOption.hasClass('active').length) {
			$deliveryOption.addClass('notify_required');
		}

		if (!$deliveryOption.hasClass('active').length) {
			$('[id^="delivery-selector-pickup-"]:not(:checked)').parents('.delivery-options').addClass('notify_required');
		}
	});

	$('.cart-delivery-popup .modal-body').on('scroll', function() {
		setPositionPac();
	});

	$('.cart-delivery-popup').on('scroll', function() {
		setPositionPac();
	});

	$(window).on('resize', function() {
		 displayCartErrorMessage();
	});

	$(window).on("orientationchange", function () {
		$('.delivery-estimate-form .tooltip').addClass('d-none');
	});

	$('.delivery-estimate-form .info-icon').click(function() {
		$('.delivery-estimate-form .tooltip').removeClass('d-none');
	});
	// $(document).on('click', '.delivery-option-fee', function(event) {
	// 	const $deliveryFee = $(this);
	// 	const parentId = $deliveryFee.data('parentid');
	// 	if (!$deliveryFee.hasClass('selected')) {
	// 		$deliveryFee.closest('.carousel-item').find('.delivery-option-fee.selected').removeClass('selected');
	// 		$deliveryFee.addClass('selected');
	// 		$(parentId).attr('data-shippingtime', $deliveryFee.data('deliverytime'));
	// 	}
	// });

	// $(document).on('click', '#atl', function(event) {
	// 	if($(this).is(':checked')){
	// 		if($('.delivery-options-agreement').length > 0 && $('.delivery-options-agreement input:not(:checked)').length > 0){

	// 		}else{
	// 			$('#atlInvalidMessage').hide();
	// 			$("#deliveryConfirmBTN").prop('disabled',false);
	// 			$("#deliveryConfirmBTN").removeClass('disabled');
	// 		}
	// 	}else{
	// 		$('#atlInvalidMessage').show();
	// 		$("#deliveryConfirmBTN").prop('disabled',true);
	// 		$("#deliveryConfirmBTN").addClass('disabled');
	// 	}
	// });

	$(document).on('click', '.delivery-options-agreement input', function (e) {
		if ($(this).is(':checked')) {
			if ($('#atl').length > 0 && $('#atl:checked').length > 0) {
			} else if ($('#atl').length < 1) {
				$('#deliveryConfirmBTN').prop('disabled', false);
				$('#deliveryConfirmBTN').removeClass('disabled');
			}
		}else{
			$('#deliveryConfirmBTN').prop('disabled', false);
			$('#deliveryConfirmBTN').removeClass('disabled');
		}
	});

	$(document).on('click', '.delivery-options-actions', function(event) {
		var $customCheckbox = $('.delivery-options .custom-control.custom-checkbox > .custom-control-label');

		if($(this).children('button.disabled').length){
			$(".storelocator-searchbox").addClass('notify_required');
			if (!!$customCheckbox) {
				$customCheckbox.addClass('invalid');
			}
		} else if (!!$customCheckbox) {
			$customCheckbox.removeClass('invalid');
		}
	});


	$(document).on('click', '.delivery-options-actions button', function(event) {
		if ($('.delivery-options-agreement').length > 0 && $('.delivery-options-agreement input:not(:checked)').length > 0) {
			$('.delivery-options-agreement label').css('color', 'red');
			return;
		}

		var isShippingChecked = true;
		$('.cart-delivery-popup [type="radio"]').each(function() {
			if (!$(this).is(':checked')) {
				isShippingChecked = false;
			}
		});

		var hasInvalidDeliveryFee = !!$('#deliveryOptionsAgreement').length;
		if (hasInvalidDeliveryFee) {
			sessionStorage.setItem('invalidDeliveryFee', true);
		} else {
			sessionStorage.removeItem('invalidDeliveryFee');
		}

		if ($('#rawDeliveryAddress').length > 0) {
			const jsonData = {};
			const redirectUrl = $(this).data('redirecturl');
			$('.cart-delivery-popup [type="radio"]:checked').each(function() {
				const $deliveryOption = $(this).closest('.delivery-option');
				const shippingMethod = $deliveryOption.data('shippingmethod');
				let encodedShippingPrice;
				let shippingPrice;
				const deliveryOptionFee = $(this).parents().eq(2).find('.carousel-item.active .delivery-option-fee.selected');
				const deliveryOption = $(this).closest('.delivery-option.selected').parent();

				if (deliveryOptionFee.length > 0 && deliveryOptionFee.find('.shippingToken').length > 0) {
					encodedShippingPrice = deliveryOptionFee.find('.shippingToken').val();
				} else if (deliveryOption.length > 0 && deliveryOption.find('.shippingToken').length > 0) {
					encodedShippingPrice = deliveryOption.find('.shippingToken').val();
				}
				shippingPrice = window.atob(encodedShippingPrice);
				jsonData[shippingMethod] = {
					price: Number(shippingPrice),
					deliverytime: $deliveryOption.attr('data-shippingtime'),
					source: $deliveryOption.data('source'),
					couriername: $deliveryOption.data('couriername'),
				};
			});
			jsonData.rawDeliveryAddress = JSON.parse($('#rawDeliveryAddress').val());

			if (isShippingChecked) {
				$.spinner().start();
				$.ajax({
					url: $(this).data('url'),
					type: 'POST',
					dataType: 'json',
					contentType: 'application/json',
					data: JSON.stringify(jsonData),
					success: function(response) {
						if (response.error) {
							$('.delivery-estimate-results .invalid-feedback').css('display', 'block');
						} else {
							$('.cart-delivery-popup .close').click();
							window.location.href = redirectUrl;
						}
					},
					error: function() {
						$.spinner().stop();
						$('.delivery-estimate-results .invalid-feedback').css('display', 'block');
					},
				});
			}
		}
	});

	$('body').on('click', '.cart-delivery-popup .close', function (e) {
		if ($('.cart-delivery-popup').find('.invalid-data').val() === 'false') {
			window.location.reload();
		}
	});

    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();
		var $this = $(this);
		var $cartItem = $this.closest('.js-card-item').find('.js-qty-control');
        var actionUrl = $this.data('action');
        var productID = $this.data('pid');
        var productName = $this.data('name');
        var uuid = $this.data('uuid');
		var quantity = ($cartItem.length ? $cartItem.val() : $this.data('quantity')) || 1;
        confirmDelete(actionUrl, productID, productName, uuid, quantity);
    });

	$(document).on('click', '.cart-page .delivery-option--selector', function(event) {
		// For back to cart url link
		if (localStorage.getItem('referrelUrl') == null) {
			localStorage.setItem('referrelUrl',  document.referrer);
		}

		if ($(this).is(':checked')) {
			var redirectUrl = $(this).closest('.delivery-option').data('redirecturl');
			$.spinner().start();
            $('.cart-totals .custom-price-sales .text-right, .strike-through, .shipment-info-left .shipment-ttl-saving, .custom-price .shipping-cost, .custom-price .shipping-discount').toggleClass('d-none', true);
            $('.cart-totals .custom-price .loading-icon').toggleClass('d-none', false);
			$.ajax({
				url: $(this).closest('.delivery-option').data('url'),
				type: 'POST',
				dataType: 'json',
				data: {
					method: $(this).val(),
					uuid: $(this).closest('.delivery-option').data('lineitem'),
				},
				success: function(response) {
					$.spinner().stop();
                    $('.cart-totals .custom-price-sales .text-right, .strike-through, .shipment-info-left .shipment-ttl-saving, .custom-price .shipping-cost, .custom-price .shipping-discount').toggleClass('d-none', false);
                    $('.cart-totals .custom-price .loading-icon').toggleClass('d-none', true);
					if (!response.error) {
						$.spinner().start();
                        $('.cart-totals .custom-price-sales .text-right, .strike-through, .shipment-info-left .shipment-ttl-saving, .custom-price .shipping-cost, .custom-price .shipping-discount').toggleClass('d-none', true);
                        $('.cart-totals .custom-price .loading-icon').toggleClass('d-none', false);
						window.location.href = redirectUrl;
					}
				},
				error: function() {
					$.spinner().stop();
                    $('.cart-totals .custom-price-sales .text-right, .strike-through, .shipment-info-left .shipment-ttl-saving, .custom-price .shipping-cost, .custom-price .shipping-discount').toggleClass('d-none', false);
                    $('.cart-totals .custom-price .loading-icon').toggleClass('d-none', true);
				},
			});
		}
	});

	$('body').on('click', '.cart-delete-confirmation-btn', function (e) {
		var buttonConfirm = this;
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();
        $.spinner().start();
        $('.cart-totals .custom-price-sales .text-right, .strike-through, .shipment-info-left .shipment-ttl-saving, .custom-price .shipping-cost, .custom-price .shipping-discount').toggleClass('d-none', true);
        $('.cart-totals .custom-price .loading-icon').toggleClass('d-none', false);
		if (window.GA4) { window.GA4.removeFromCartClick(buttonConfirm); }

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function(data) {
				window.location.reload();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
					window.location.reload();
                }
            }
        });
    });

	$('body').on('afterRemoveFromCart', function(e, data) {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

	$('body').on('click', '.remove-all-product', function (e) {
        var pids = $(this).data('pids');
        var url = $(this).data('action');
        $.spinner().start();

        $.ajax({
            url: url,
            type: 'POST',
			dataType: 'json',
			data: {pids: pids},
            success: function(data) {
				window.location.reload();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
					window.location.reload();
                }
            }
        });
    });

	$(document).on('click', '.checkout-btn:not(.disabled)', function(e) {
		if (isGTMEnabled && window.dataLayer) {
			const gtmTrackingUrl = $(this).closest('.checkout-and-applepay').data('gtmtracking');
			const continueUrl = $(this).closest('.checkout-and-applepay').data('continueurl');
			if (gtmTrackingUrl) {
				e.preventDefault();
				$.ajax({
					url: gtmTrackingUrl,
					type: 'GET',
					cache: false,
					dataType: 'json',
					success: function(response) {
						if (!response.error && response.data && response.data.length > 0) {
							response.data.forEach(function(gtmData) {
								window.dataLayer.push(gtmData);
							});
						}
						window.location.href = continueUrl;
					},
					error: function() {
						window.location.href = continueUrl;
					},
				});
			}
		}
	});

	$('body').on('keyup', '#estimate-input', function() {
		const inputString = $('#estimate-input').val();
		shippingAddressHelper.validateDeliveryAddress(inputString, 'cart');
	});

    $('.promo-code-form').submit(function(e) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        const $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();

        if($form.find('#couponCode').val() === '') {
            return;
        }

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function(data) {
                if (data) {
                    if (data.error) {
                        $('.promo-code-form .form-control').addClass('is-invalid');
                        $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                        $('.coupon-error-message').empty().append(data.errorMessage);
                    } else {
                        $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                        updateCartTotals(data);
                        updateApproachingDiscounts(data.approachingDiscounts);
                        validateBasket(data);

                        if (data.totals && data.totals.discounts.length > 0) {
                            if (!data.existShippingAddress && data.couponApplied && data.totals.totalShippingCost !== "Free") {
                                $('.checkout-btn').addClass('disabled');
                            } else if (!data.existShippingAddress && data.totals.totalShippingCost !== "Free") {
                                $('.checkout-btn').addClass('disabled');
                            } else {
                                $('.checkout-btn').removeClass('disabled');
                            }
                            if (data.couponApplied === false) {
                                $('.promo-code-form .form-control').addClass('is-invalid');
                                $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                                $('.coupon-error-message').empty().append(data.couponAppliedFalse);
                            } else {
                                updateShipmentsPrice(data);
                            }
                            if (data.totals && data.totals.totalShippingCost && data.totals.totalShippingCost === '$0.00') {
                                $('.shipping-cost')
                                    .empty()
                                    .append('$ &mdash;');
                            }
                        } else if (data.couponApplied === false) {
                            if (data.shipments && data.shipments.length === 1 && data.shipments[0].selectedShippingMethod === "pickup") {
                                $('.shipping-cost').empty().append('FREE');
                            }
                            $('.promo-code-form .form-control').addClass('is-invalid');
                            $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                            $('.coupon-error-message').empty().append(data.couponAppliedFalse);
                        }
                    }
                }
                $('.coupon-code-field').val('');
                $.spinner().stop();
            },
            error: function(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            },
		});
	});

	$(document).on('click', '.remove-product-variation', function(e) {
		const itemId = $(this).data('pid');
  		handleItemClose(itemId, $(this));
	});

	$(document).ready(function() {
		if (
			$('.cart.cart-page') &&
			$('.cart.cart-page').length > 0 &&
			localStorage.getItem('gtm-cart-update-values') &&
			localStorage.getItem('gtm-cart-update-values') !== null
		) {
			const gtmCartEvent = localStorage.getItem('gtm-cart-update-values');
			window.dataLayer.push(JSON.parse(gtmCartEvent));
			localStorage.removeItem('gtm-cart-update-values');
		}
		checkHiddenItems();
	});

    $(document).on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $(document).on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket(data);
                updateShipmentsPrice(data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            },
        });
    });
};

module.exports.getPostcodeByLat = getPostcodeByLat;
