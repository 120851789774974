require('bootstrap/js/src/collapse.js');
require('slick-carousel');
window.jQuery = window.$ = require('jquery');
const parentDetail = require('./detailGtm');
var pswpImage = require('../components/pswp-product-image');
var swatchPdp = require('./swatchPdp');
var tabSticky = require('./tabSticky');

function attributeSelect(selectedValueUrl, $productContainer){
    if (selectedValueUrl) {
        //$('body').trigger('product:beforeAttributeSelect', { url: selectedValueUrl, container: $productContainer });
        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                handleVariantResponse(data, $productContainer);
                updateOptions(data.product.optionsHtml, $productContainer);
                if (!data.product.isMattress) {
                    updateQuantities(data.product.quantities, $productContainer);
                }
                $('body').trigger('product:afterAttributeSelect', { data: data, container: $productContainer });
                $.spinner().stop();
                $(document).off('change', '.quantity-select');
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
    if (!($productContainer.parent('.bonus-product-item').length > 0)) {
        var optionsHtml = quantities.map(function (quantity) {
            var selected = quantity.selected ? ' selected ' : '';
            return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"' +
                selected + '>' + quantity.value + '</option>';
        }).join('');
        getQuantitySelector($productContainer).empty().html(optionsHtml);
    }
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    return $el && $('.set-items').length
        ? $($el).closest('.product-detail').find('.quantity-select')
        : $('.quantity-select');
}

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} optionsHtml - Ajax response optionsHtml from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(optionsHtml, $productContainer) {
    // Update options
    $productContainer.find('.product-options').empty().html(optionsHtml);
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 * @param {Object} addOnData - object add-on Products Data
 */
function updateAttrs(attrs, $productContainer, msgs, addOnData) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color'];
    var attrsWithMattress = ['mattressFeel', 'mattressSize'];

    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer, msgs);
        } else if(attrsWithMattress.indexOf(attr.id) > -1) {
            processMattressValues(attr, $productContainer);
            processAddOnProducts(addOnData, $productContainer);
        } else {
            processNonSwatchValues(attr, $productContainer);
        }
    });
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processSwatchValues(attr, $productContainer, msgs) {
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' +
            attrValue.value + '"]');
        var $swatchButton = $attrValue.parent();

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
        }

        if (attrValue.url) {
            $swatchButton.attr('data-url', attrValue.url);
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
    });
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';
    var $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:first');
    $defaultOption.attr('value', attr.resetUrl);

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer
            .find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('value', attrValue.url)
            .removeAttr('disabled');

        if (!attrValue.selectable) {
            $attrValue.attr('disabled', true);
        }
    });
}

function processMattressValues(attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';
    var $defaultOption = $productContainer.find('.default-select-mattress-' + attr.id);
    $('.addon-checkbox').prop('checked', false);

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer
            .find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('value', attrValue.value).removeClass('d-none');
        $attrValue.removeClass('unavailable');
        $attrValue.find('.text-unavailable').addClass('d-none');
        $attrValue.attr('data-url', attrValue.url);
        $attrValue.find('.custom-option-price .price .sales .value').attr('content', attrValue.priceSales);
        $attrValue.find('.custom-option-price .price .sales .value').text(attrValue.priceSales);
        if (!attrValue.price) {
            $attrValue.find('.custom-option-price .price del').addClass('d-none');
            $attrValue.find('.custom-option-price').removeClass('is-sale');
        } else {
            $attrValue.find('.custom-option-price').addClass('is-sale');
            $attrValue.find('.custom-option-price .price del').removeClass('d-none');
            $attrValue.find('.custom-option-price .price .list .value').attr('content', attrValue.price);
            $attrValue.find('.custom-option-price .price .list .value').text(attrValue.price);
        }

        if (attrValue.selected && attrValue.priceSales) {
            $defaultOption.find('.custom-option-price .price .sales .value').attr('content', attrValue.priceSales);
            $defaultOption.find('.custom-option-price .price .sales .value').text(attrValue.priceSales);
        }

        if (attrValue.selected && attrValue.priceSales) {
            $productContainer.find('.product-code').html('<span>Product code:</span> '+attrValue.pid);
        }

        if (!attrValue.selectable) {
            if (attr.id === 'mattressSize') { $attrValue.addClass('d-none'); }
            $attrValue.addClass('unavailable');
            $attrValue.find('.text-unavailable').removeClass('d-none');
        }

        if (attr.id === 'mattressFeel') {
            $attrValue.find('a').attr('href', attrValue.variationUrl);
        }
    });
}

function processAddOnProducts(addOnData, $productContainer) {
    var $mattressAddon = $productContainer.find('.mto-attribute.mattress-addon');
    if($mattressAddon.length) {
        $mattressAddon.find('label.label-mto-attribute, .base-product.addon-item').remove();
        $mattressAddon.prepend(addOnData.addOnProductsHtml);
        swatchPdp.swatchDisplay();
    }
}

/**
 * Dynamically creates Bootstrap carousel from response containing images
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function createCarousel(imgs, $productContainer) {
    var carousel = $productContainer.find('.carousel');
    if(carousel && carousel.length){
        $(carousel).carousel('dispose');
        var carouselId = $(carousel).attr('id');
        $(carousel).empty().append('<ol class="carousel-indicators"></ol><div class="carousel-inner" role="listbox"></div><a class="carousel-control-prev" href="#' + carouselId + '" role="button" data-slide="prev"><span class="fa icon-prev" aria-hidden="true"></span><span class="sr-only">' + $(carousel).data('prev') + '</span></a><a class="carousel-control-next" href="#' + carouselId + '" role="button" data-slide="next"><span class="fa icon-next" aria-hidden="true"></span><span class="sr-only">' + $(carousel).data('next') + '</span></a>');
        for (var i = 0; i < imgs.length; i++) {
            $('<div class="carousel-item"><img src="' + imgs[i].url + '" class="d-block img-fluid" alt="' + imgs[i].alt + ' image number ' + parseInt(imgs[i].index, 10) + '" title="' + imgs[i].title + '" itemprop="image" /></div>').appendTo($(carousel).find('.carousel-inner'));
            $('<li data-target="#' + carouselId + '" data-slide-to="' + i + '" class=""></li>').appendTo($(carousel).find('.carousel-indicators'));
        }
        $($(carousel).find('.carousel-item')).first().addClass('active');
        $($(carousel).find('.carousel-indicators > li')).first().addClass('active');
        if (imgs.length === 1) {
            $($(carousel).find('.carousel-indicators, a[class^="carousel-control-"]')).detach();
        }
        $(carousel).carousel();
        $($(carousel).find('.carousel-indicators')).attr('aria-hidden', true);
    }
}

function createPDPImage(imgs, $productContainer) {
    var productImagePrimary = $productContainer.find('.product-image-primary');
    var productImageNav = $productContainer.find('.product-image-nav');
    var heightProductOverlay = $('.product-detail-image .product-overlay-wrapper').height();
    var heightProductImageNav = $('.product-detail-image .product-image-nav').height();

    if (productImagePrimary && productImagePrimary.length) {
        if (productImagePrimary.hasClass('slick-initialized')) {
            productImagePrimary.slick('unslick');
        }
        productImagePrimary.empty();
        $('.product-detail-image .product-overlay-wrapper').css('height', heightProductOverlay);

        for (var i = 0; i < imgs.large.length; i++) {
            let img = imgs.large[i];
            let primarySlide = $('<div class="image-nav"><img data-index="' + i + '" src="' + img.url + '" fetchpriority="high" class="img-fluid pswp-image" data-img-full="'+ img.url +'" alt="' + img.alt + '" title="' + img.alt + '"></div>');
            productImagePrimary.append(primarySlide);
        }

        productImagePrimary.on('beforeChange', function(event, slick){
            $('.product-detail-image .product-overlay-wrapper').css('height', 'inherit');
        });

        productImagePrimary.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.product-image-nav',
        });

        pswpImage.initPswpImage();
    }

    if (productImageNav && productImageNav.length) {
        if (productImageNav.hasClass('slick-initialized')) {
            productImageNav.slick('unslick');
        }
        productImageNav.empty();
        $('.product-detail-image .product-image-nav').height(heightProductImageNav);

        for (var i = 0; i < imgs.large.length; i++) {
            let img = imgs.large[i];
            let navSlide = $('<div class="image-nav"><img data-index="' + i + '" src="' + img.url + '" class="img-fluid" alt="' + img.alt + '" title="' + img.alt + '"></div>');
            productImageNav.append(navSlide);
        }

        const productImageQ = productImageNav.find('.image-nav').length;
        productImageNav.slick({
            slidesToShow: 6,
            slidesToScroll: 1,
            asNavFor: '.product-image-primary',
            dots: false,
            centerMode: false,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 3,
                    },
                },
            ],
        });

        let slidesToShow = $(window).width() > 768 ? 6 : 3;

        if (slidesToShow >= productImageQ) {
            productImageNav.find('.slick-track').addClass('no-move');
        }

        $(window).on('resize', function() {
            slidesToShow = $(window).width() > 768 ? 6 : 3;

            if (slidesToShow >= productImageQ) {
                productImageNav.find('.slick-track').addClass('no-move');
            } else {
                productImageNav.find('.slick-track.no-move').removeClass('no-move');
            }
        });
    }
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts =
        $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVariant;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer, response.resources, { addOnProductsHtml: response.addOnProductsHtml });
        isVariant = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVariant) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }

        if (response.product.isMattress && isVariant) {
            $productContainer.attr('data-pid', response.product.id);
            var $pdpATC = $productContainer.find('.add-to-cart');
            if ($pdpATC.length) {
                $pdpATC.attr('data-pid', response.product.id);
                $pdpATC.removeAttr('data-gtm');
                $pdpATC.attr('data-gtm', response.toAddToCartClickHtml);
            }
            if ($productContainer.find('.pdp-checkout-button').attr('data-store') === 'false') {
                $productContainer.find('.pdp-checkout-button').removeAttr('data-gtm');
                $productContainer.find('.pdp-checkout-button').attr('data-gtm', response.toAddToCartClickHtml);
            }

            if ($productContainer.find('.storeselect-toggle').attr('data-store') === 'false') {
                $productContainer.find('.storeselect-toggle').removeAttr('data-gtm');
                $productContainer.find('.storeselect-toggle').attr('data-gtm', response.toAddToCartClickHtml);
            }

            var $quickviewATC = $productContainer.closest('.mattress-quick-view-dialog').find('.quickview-event-buttons');
            var $quickviewDetails = $productContainer.closest('.mattress-quick-view-dialog').find('.quick-details-button');
            if ($quickviewATC.length) {
                $quickviewATC.attr('data-pid', response.product.id);
                $quickviewATC.find('.add-to-cart-button').removeAttr('data-gtm');
                $quickviewATC.find('.add-to-cart-button').attr('data-gtm', response.toAddToCartClickHtml);
                $quickviewATC.find('.select-store-button').attr('data-gtm', response.toAddToCartClickHtml);
            }
            if ($quickviewDetails.length) {
                $quickviewDetails.removeAttr('data-gtm');
                $quickviewDetails.attr('data-gtm', response.toProductClickHtml);
                $quickviewDetails.attr('href', response.productUrl);
            }

            if (response.isInWishList === true) {
                $productContainer.find('.wishlistTile .fa').removeClass('fa-heart-o');
                $productContainer.find('.wishlistTile .fa').addClass('fa-heart');
                $productContainer.find('.wishlistTile').attr('href', $("#removeWishlistURL").val());
            } else {
                $productContainer.find('.wishlistTile .fa').removeClass('fa-heart');
                $productContainer.find('.wishlistTile .fa').addClass('fa-heart-o');
                $productContainer.find('.wishlistTile').attr('href', $("#addWishlistURL").val());
            }
        }
    }
    // Update Product Title
    updateProductName(response, $productContainer);

    // Update primary images
    var primaryImageUrls = response.product.images.large;
    createCarousel(primaryImageUrls, $productContainer);
    createPDPImage(response.product.images, $productContainer);
    updateOverlayBadge(response.product.overlays, $productContainer);
    updateDisclaimer(response.product, $productContainer)
    updateWarrantyBadge(response.product.warranty, $productContainer);
    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.prices .price', $productContainer).length
            ? $('.prices .price', $productContainer)
            : $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);
        if (response.product.price.list === null) {
            $productContainer.find('.savedPrice').addClass('d-none');
            $productContainer.find('.sales-msg').addClass('d-none');
        } else {
            var priceList = response.product.price.list.decimalPrice;
            var priceSale = response.product.price.sales.decimalPrice;
            var savePrice = priceList - priceSale;
            savePrice = savePrice.toFixed(2);
            if (savePrice % 1 == 0) {
                savePrice = parseInt(savePrice);
            }
            var $salesMsg = $productContainer.find('.sales-msg');
            if (savePrice && !$salesMsg.length) {
                var $saveBadge = $('<div class="sales-msg-right"><div class="sales-msg">SAVE<br />$<span id="demo"></span></div></div>');
                $productContainer.find('.product-overlay-wrapper-top').append($saveBadge);
                $salesMsg = $productContainer.find('.sales-msg');
            }
            $productContainer.find('.product-overlay-wrapper-top').removeClass('d-none');
            $productContainer.find('.savedPrice').removeClass('d-none');
            $productContainer.find('.savedPrice').html('SAVE $' + savePrice);
            $salesMsg.removeClass('d-none');
            $salesMsg.html('SAVE<br/> $' + savePrice);
        }
    }
    // Update promotions
    $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);

    updateAvailability(response, $productContainer);
    var $trustpilotWidgets = $productContainer.find('.product-detail__content .trustpilot-widget, #tpreviews .trustpilot-widget');
    if ($trustpilotWidgets.length) {
        tabSticky.toggleReviews();
        $trustpilotWidgets.attr('data-sku', response.trustPilotProductIDs);
        $trustpilotWidgets.each(function() {
            window.Trustpilot.loadFromElement(this, true);
        });
    }

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty()
        .html(getAttributesHtml(response.product.attributes));
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': '
                    + attribute.value + '</div>';
            });
        }
    });

    return html;
}

function updatePriceInAddToCartButton(price) {
    var html = '';
    html += '<span class="mattress-total-price"><span class="sales">';
    html += '<span class="value" name="price" content="'+price.sales.decimalPrice+'">'+price.sales.formatted+'</span>';
    html += '</span></span>';
    return html;
}

function updateOverlayBadge(overlays, $productContainer) {
    var overlaysList = $productContainer.find('.product-detail-image .product-overlays-list');
    overlaysList.empty();
    for (var i = 0; i < overlays.length; i++) {
        let overlay = $('<img class="lazyload overlay-image overlay.id" src="'+overlays[i].listingImage.httpsURL.toString()+'" alt="overlay.name"/>');
        overlaysList.append(overlay);
    }
}

function updateWarrantyBadge(warranty, $productContainer) {
    var overlaysList = $productContainer.find('.product-detail-image .product-overlays-list');
    if (warranty) {
        let $warrantyImg = $('<img class="product-warranty-badge" src="'+warranty+'" alt=""/>');
        overlaysList.append($warrantyImg);
    }
}

function updateDisclaimer(product, $productContainer) {
    var disclaimerContent = $productContainer.find('.disclaimer-content');
    var isComingSoon = $productContainer.find('.isComingSoonContent');
    var notOnDisplay = $productContainer.find('.notOnDisplay');
    var isMto = $productContainer.find('.isMtoContent');
    var onlineOnly = $productContainer.find('.onlineOnlyContent');

    if (disclaimerContent.length > 0) {
        disclaimerContent.empty();
        if (product.notOnDisplayContent && notOnDisplay.length === 0) {
            $('.mto-notice-pdp-no-border').removeClass('d-none');
            var notOnDisplayContent = '<div class="delivery-line store-display-info">' + product.notOnDisplayContent + '</div>';
            disclaimerContent.append(notOnDisplayContent);
        }
        if (product.isComingSoonContent && isComingSoon.length === 0) {
            $('.mto-notice-pdp-no-border').removeClass('d-none');
            var isComingSoonContent = '<div class="mto-notice-pdp mto-notice-pdp-no-border">' + product.isComingSoonContent + '</div>';
            disclaimerContent.append(isComingSoonContent);
        }
        if (product.isMtoContent && isMto.length === 0) {
            onlineOnly.addClass('d-none');
            var isMtoContent = '<div class="mto-notice-pdp mto-notice-pdp-no-border">' + product.isMtoContent + '</div>';
            disclaimerContent.append(isMtoContent);
        }
        if (product.onlineOnlyContent && onlineOnly.length === 0) {
            $('.mto-notice-pdp-no-border').removeClass('d-none');
            var onlineOnlyContent = '<div class="mto-notice-pdp mto-notice-pdp-no-border">' + product.onlineOnlyContent + '</div>';
            disclaimerContent.append(onlineOnlyContent);
        }
    }
}

function updateProductName(response, $productContainer) {
    const pdpSidebar = $productContainer.find('.pdp-sidebar');
    if (pdpSidebar.length) {
        var productName = pdpSidebar.find('.product-name');
        var productDescription = pdpSidebar.find('.product-description');
        var productLongDescription = $productContainer.find('.product-accordion').find('.product-long-description');
        productName.text(response.product.productName);
        productDescription.text(response.product.shortDescription);
        productLongDescription.empty().html(response.product.longDescription);
    }

    const quickviewProduct = $productContainer.find('.quickview-product-right');
    if (quickviewProduct.length) {
        var productName = quickviewProduct.find('.product-name');
        var productDescription = quickviewProduct.find('.product-description');
        var productLongDescription = $productContainer.find('.product-accordion').find('.product-long-description');
        productName.text(response.product.productName);
        productDescription.text(response.product.shortDescription);
        productLongDescription.empty().html(response.product.longDescription);
    }
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    var hasPreferredStore = response.product.availability.hasPreferredStore;
    var isMattress = response.product.isMattress;
    var price = response.product.price;
    var available = response.product.available;
    var readyToOrder = response.product.readyToOrder;
    var onlineOnly = response.product.onlineOnly;
    if (!response.product.readyToOrder) {
        availabilityValue = '<li><div>' + response.resources.info_selectforstock + '</div></li>';
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<li><div>' + message + '</div></li>';
        });
    }

    if (response.product.isComingSoon === true) {
        $('.group-add-to-cart-quantity').addClass('d-none');
        $('.iscomingsoon-status').addClass('d-none');
        $('.boomgate-delivery-localtion-block').addClass('d-none');
        $('.isComingSoonContent').removeClass('d-none');
        $('.store-display-info').removeClass('d-none');
    } else {
        $('.group-add-to-cart-quantity').removeClass('d-none');
        $('.iscomingsoon-status').removeClass('d-none');
        $('.boomgate-delivery-localtion-block').removeClass('d-none');
        $('.isComingSoonContent').addClass('d-none');
        $('.store-display-info').addClass('d-none');
    }
    if (response.product.isMto === true) {
        $('.isMtoContent').removeClass('d-none');
    } else {
        $('.isMtoContent').addClass('d-none');
    }
    if (onlineOnly === true) {
        $('.onlineOnlyContent').removeClass('d-none');
        $('.delivery-line-not-display').removeClass('d-none');
    } else {
        $('.onlineOnlyContent').addClass('d-none');
        $('.delivery-line-not-display').addClass('d-none');
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });

    if ($('.delivery-my-store .delivery-options').length) {
        if(response.product.availability && response.product.availability.pickup){
            var pickupHTML = '';
            var pickupClass = 'pickup-';
            var disabledPickupButton = false;
            // Redraw pickup availability
            if(response.product.availability.pickup.length &&
                response.product.availability.pickup[0].available){
                pickupHTML += '<span><b>Pickup - </b><span class="status-pickup-pdp-store">';
                var pickupColor = '';
                var pickupText = '';
                if(response.product.availability.pickup[0].dateAvailable &&
                    response.product.availability.pickup[0].dateAvailable == 'Due in Store'){
                    pickupClass += 'low';
                    pickupColor = response.pickupAttribute.amber_icon_1.color;
                    pickupText = response.pickupAttribute.amber_icon_1.text;
                    pickupHTML += pickupText;
                }else if(response.product.availability.pickup[0].dateAvailable
                    && (response.product.availability.pickup[0].dateAvailable === '< 1 Week' || response.product.availability.pickup[0].dateAvailable === '<= 1 Week')){
                    pickupColor = response.pickupAttribute.green_icon_2.color;
                    pickupText = response.pickupAttribute.green_icon_2.text;
                    pickupClass += 'low';
                    pickupHTML += pickupText;
                }else if(response.product.availability.pickup[0].dateAvailable && response.product.availability.pickup[0].dateAvailable.indexOf('<= n Weeks') >= 0){
                    pickupColor = response.pickupAttribute.green_icon_3.color;
                    pickupText = response.pickupAttribute.green_icon_3.text;
                    pickupClass += 'low';
                    pickupHTML += pickupText;
                }else if(response.product.availability.pickup[0].dateAvailable){
                    pickupColor = response.pickupAttribute.amber_icon_2.color;
                    pickupText = response.pickupAttribute.amber_icon_2.text;
                    pickupClass += 'low';
                    pickupHTML += pickupText;
                }else{
                    pickupClass += 'available';
                    pickupColor = response.pickupAttribute.green_icon_1.color;
                    pickupText = response.pickupAttribute.green_icon_1.text;
                    pickupHTML += pickupText;
                }
                pickupHTML += '</span></span>';
            }else{
                pickupClass += "unavailable";
                pickupColor = response.pickupAttribute.grey_icon.color;
                pickupHTML += "<span><b>Pickup - </b> " + response.pickupAttribute.grey_icon.text + "</span>";
                disabledPickupButton = true;
            }
            if(pickupHTML){
                var dateAvailable = response.product.availability.pickup[0].dateAvailable;
                if (dateAvailable) {
                    dateAvailable = dateAvailable.replace('<= n Weeks -> ', '');
                    dateAvailable = dateAvailable.replace('> n Weeks -> ', '');
                    dateAvailable = dateAvailable.replace('<= 1 Week', '');
                    pickupHTML = pickupHTML.replace('{n}', dateAvailable);
                }
                $('.delivery-options #pdpPickupAvailability')
                .removeClass('pickup-low')
                .removeClass('pickup-available')
                .removeClass('pickup-unavailable')
                .addClass(pickupClass)
                .css('--pickupAvailabilityColor', pickupColor)
                .empty()
                .html(pickupHTML);
            }
        } // pickup done.

        if(response.product.availability && response.product.availability.delivery){
            var deliveryHTML = '';
            var deliveryClass = 'delivery-';
            var disabledDeliveryButton = false;
            // Redraw delivery availability
            if(response.product.availability.delivery.available){
                deliveryHTML += '<span><b>Delivery - </b><span class="status-delivery-pdp-store">';
                var deliveryColor = '';
                var deliveryText = '';
                if(response.product.availability.delivery.dateAvailable &&
                    (response.product.availability.delivery.dateAvailable === '< 1 Week' || response.product.availability.delivery.dateAvailable === '<= 1 Week')){
                        deliveryClass += 'low';
                        deliveryColor = response.deliveryAttribute.green_icon_2.color;
                        deliveryText = response.deliveryAttribute.green_icon_2.text;
                        deliveryHTML += deliveryText;
                }else if(response.product.availability.delivery.dateAvailable && response.product.availability.delivery.dateAvailable.indexOf('<= n Weeks') >= 0){
                    deliveryColor = response.deliveryAttribute.green_icon_3.color;
                    deliveryText = response.deliveryAttribute.green_icon_3.text;
                    deliveryClass += 'low';
                    deliveryHTML += deliveryText;
                }else if(response.product.availability.delivery.dateAvailable){
                    deliveryColor = response.deliveryAttribute.amber_icon.color;
                    deliveryText = response.deliveryAttribute.amber_icon.text;
                    deliveryClass += 'low';
                    deliveryHTML += deliveryText;
                }else{
                    deliveryColor = response.deliveryAttribute.green_icon_1.color;
                    deliveryText = response.deliveryAttribute.green_icon_1.text;
                    deliveryClass += 'available';
                    deliveryHTML += deliveryText;
                }
                deliveryHTML += '</span></span>';
            }else{
                deliveryClass += "unavailable";
                deliveryColor = response.deliveryAttribute.grey_icon.color;
                deliveryHTML += "<span><b>Delivery - </b> "+ response.deliveryAttribute.grey_icon.text +"</span>";
                disabledDeliveryButton = true;
            }
            if(deliveryHTML){
                var dateAvailable = response.product.availability.delivery.dateAvailable;
                if (dateAvailable) {
                    dateAvailable = dateAvailable.replace('<= n Weeks -> ', '');
                    dateAvailable = dateAvailable.replace('> n Weeks -> ', '');
                    dateAvailable = dateAvailable.replace('<= 1 Week', '');
                    deliveryHTML = deliveryHTML.replace('{n}', dateAvailable);
                }
                var target = $('.delivery-options #pdpDeliveryAvailability');
                target.removeClass('delivery-low')
                .removeClass('delivery-available')
                .removeClass('delivery-unavailable')
                .addClass(deliveryClass)
                .css('--stockAvailabilityColor', deliveryColor)
                .empty()
                .html(deliveryHTML);
            }
        } // delivery done.

        if (disabledPickupButton === true && disabledDeliveryButton === true) {
            $('.pdp-checkout-button .add-to-cart').attr('disabled','disabled');
            $('.pdp-checkout-button .add-to-cart').html('Out of Stock');
        } else {
            $('.pdp-checkout-button .add-to-cart').removeAttr('disabled');
            if (isMattress && available && readyToOrder) {
                var htmlATC = '';
                htmlATC += updatePriceInAddToCartButton(price);
                htmlATC += ' - ';
                htmlATC += 'Add to Cart';
                $('.pdp-checkout-button .add-to-cart').html(htmlATC);
            } else {
                $('.pdp-checkout-button .add-to-cart').html('Add to Cart');
            }
        }
    } else if ($productContainer.find('.mattress-product').length && isMattress) {
        const infoProduct = $productContainer.find('.pdp-checkout-button .info-product');
        if (infoProduct.attr('data-store') === 'false' || onlineOnly) {
            var htmlATC = '';
            htmlATC += updatePriceInAddToCartButton(price);
            htmlATC += ' - ';
            htmlATC += 'Add to Cart';
            infoProduct.html(htmlATC);
            if (onlineOnly) {
                infoProduct.addClass('add-to-cart');
                infoProduct.removeClass('storeselect-toggle');
                infoProduct.removeClass('add-to-cart-style-store-locator');
            } else {
                infoProduct.removeClass('add-to-cart');
                infoProduct.addClass('storeselect-toggle');
                infoProduct.addClass('add-to-cart-style-store-locator');
            }
        }
    }

    if ($('.product-quickview').length) {
        const quickviewButtons = $('.quickview-event-buttons');
        const infoProduct = $('.quickview-event-buttons .info-product');
        $('.quickview-event-buttons .info-product').removeAttr('disabled');
        if (isMattress) {
            if (quickviewButtons.attr('data-store') === 'false' || quickviewButtons.attr('data-store') === 'null' || !hasPreferredStore) {
                var htmlATC = '';
                htmlATC += updatePriceInAddToCartButton(price);
                htmlATC += ' - ';
                htmlATC += 'Add to Cart';
                infoProduct.html(htmlATC);
                quickviewButtons.attr('data-store', hasPreferredStore);
                if (onlineOnly) {
                    infoProduct.addClass('add-to-cart-button');
                    infoProduct.removeClass('select-store-button');
                } else {
                    infoProduct.removeClass('add-to-cart-button');
                    infoProduct.addClass('select-store-button');
                }
            } else {
                if (available && readyToOrder) {
                    var htmlATC = '';
                    htmlATC += updatePriceInAddToCartButton(price);
                    htmlATC += ' - ';
                    htmlATC += 'Add to Cart';
                    infoProduct.removeClass('plp-out-of-stock');
                    infoProduct.addClass('add-to-cart-button');
                    infoProduct.html(htmlATC);
                    infoProduct.attr('data-gtm', response.toAddToCartClickHtml);
                    infoProduct.attr('onclick', 'GA4.addToCartClick(this);');
                } else {
                    infoProduct.attr('disabled','disabled');
                    infoProduct.addClass('plp-out-of-stock');
                    infoProduct.removeClass('add-to-cart-button');
                    infoProduct.html('Out of Stock');
                }
            }
        }
    }

    if ($('.delivery-store-list .delivery-options').length) {
        if(response.product.availability && response.product.availability.pickup){
            for (var i = 1; i < response.product.availability.pickup.length; i++) {
                var pickupItem = response.product.availability.pickup[i];
                var pickupHTML = '';
                var pickupClass = 'pickup-';
                var disabledPickupButton = false;
                if(pickupItem && pickupItem.available){
                    pickupHTML += '<span><b>Pickup - </b>';
                    var pickupColor = '';
                    var pickupText = '';
                    if(pickupItem.pickupDateAvailable &&
                        pickupItem.pickupDateAvailable == 'Due in Store'){
                        pickupClass += 'low';
                        pickupColor = response.pickupAttribute.amber_icon_1.color;
                        pickupText = response.pickupAttribute.amber_icon_1.text;
                        pickupHTML += pickupText;
                    }else if(pickupItem.pickupDateAvailable
                        && (pickupItem.pickupDateAvailable === '< 1 Week' || pickupItem.pickupDateAvailable === '<= 1 Week')){
                        pickupColor = response.pickupAttribute.green_icon_2.color;
                        pickupText = response.pickupAttribute.green_icon_2.text;
                        pickupClass += 'low';
                        pickupHTML += pickupText;
                    }else if(pickupItem.pickupDateAvailable && pickupItem.pickupDateAvailable.indexOf('<= n Weeks') >= 0){
                        pickupColor = response.pickupAttribute.green_icon_3.color;
                        pickupText = response.pickupAttribute.green_icon_3.text;
                        pickupClass += 'low';
                        pickupHTML += pickupText;
                    }else if(pickupItem.pickupDateAvailable){
                        pickupColor = response.pickupAttribute.amber_icon_2.color;
                        pickupText = response.pickupAttribute.amber_icon_2.text;
                        pickupClass += 'low';
                        pickupHTML += pickupText;
                    }else{
                        pickupClass += 'available';
                        pickupColor = response.pickupAttribute.green_icon_1.color;
                        pickupText = response.pickupAttribute.green_icon_1.text;
                        pickupHTML += pickupText;
                    }
                }else{
                    pickupClass += "unavailable";
                    pickupColor = response.pickupAttribute.grey_icon.color;
                    pickupHTML += "<span><b>Pickup - </b> " + response.pickupAttribute.grey_icon.text + "</span>";
                    disabledPickupButton = true;
                }
                if(pickupHTML){
                    var dateAvailable = pickupItem.pickupDateAvailable;
                    if (dateAvailable) {
                        dateAvailable = dateAvailable.replace('<= n Weeks -> ', '');
                        dateAvailable = dateAvailable.replace('> n Weeks -> ', '');
                        dateAvailable = dateAvailable.replace('<= 1 Week', '');
                        pickupHTML = pickupHTML.replace('{n}', dateAvailable);
                    }
                    $('.store-id-'+pickupItem.storeID).find('.delivery-options #pdpPickupAvailability')
                    .removeClass('pickup-low')
                    .removeClass('pickup-available')
                    .removeClass('pickup-unavailable')
                    .addClass(pickupClass)
                    .css('--pickupAvailabilityColor', pickupColor)
                    .empty()
                    .html(pickupHTML);
                }

                var deliveryHTML = '';
                var deliveryClass = 'delivery-';
                var disabledDeliveryButton = false;
                // Redraw delivery availability
                if(pickupItem.delivery){
                    deliveryHTML += '<span><b>Delivery - </b>';
                    var deliveryColor = '';
                    var deliveryText = '';
                    if(pickupItem.deliveryDateAvailable &&
                        (pickupItem.deliveryDateAvailable === '< 1 Week' || pickupItem.deliveryDateAvailable === '<= 1 Week')){
                            deliveryClass += 'low';
                            deliveryColor = response.deliveryAttribute.green_icon_2.color;
                            deliveryText = response.deliveryAttribute.green_icon_2.text;
                            deliveryHTML += deliveryText;
                    }else if(pickupItem.deliveryDateAvailable && pickupItem.deliveryDateAvailable.indexOf('<= n Weeks') >= 0){
                        deliveryColor = response.deliveryAttribute.green_icon_3.color;
                        deliveryText = response.deliveryAttribute.green_icon_3.text;
                        deliveryClass += 'low';
                        deliveryHTML += deliveryText;
                    }else if(pickupItem.deliveryDateAvailable){
                        deliveryColor = response.deliveryAttribute.amber_icon.color;
                        deliveryText = response.deliveryAttribute.amber_icon.text;
                        deliveryClass += 'low';
                        deliveryHTML += deliveryText;
                    }else{
                        deliveryColor = response.deliveryAttribute.green_icon_1.color;
                        deliveryText = response.deliveryAttribute.green_icon_1.text;
                        deliveryClass += 'available';
                        deliveryHTML += deliveryText;
                    }
                }else{
                    deliveryClass += "unavailable";
                    deliveryColor = response.deliveryAttribute.grey_icon.color;
                    deliveryHTML += "<span><b>Delivery - </b> "+ response.deliveryAttribute.grey_icon.text +"</span>";
                    disabledDeliveryButton = true;
                }
                if(deliveryHTML){
                    var dateAvailable = pickupItem.deliveryDateAvailable;
                    if (dateAvailable) {
                        dateAvailable = dateAvailable.replace('<= n Weeks -> ', '');
                        dateAvailable = dateAvailable.replace('> n Weeks -> ', '');
                        dateAvailable = dateAvailable.replace('<= 1 Week', '');
                        deliveryHTML = deliveryHTML.replace('{n}', dateAvailable);
                    }
                    var target = $('.store-id-'+pickupItem.storeID).find('.delivery-options #pdpDeliveryAvailability');
                    target.removeClass('delivery-low')
                    .removeClass('delivery-available')
                    .removeClass('delivery-unavailable')
                    .addClass(deliveryClass)
                    .css('--stockAvailabilityColor', deliveryColor)
                    .empty()
                    .html(deliveryHTML);
                }

            }
        }
    }
}

function asyncLoad(productObject) {
    if (productObject.latitudeMerchantId && productObject.latitudeMerchantUrl) {
        window.LatitudeCheckout = {
            "merchantId": productObject.latitudeMerchantId,
            "page": "product",
            "container": productObject.latitudeContainer,
            "containerClass": "latitude-product",
            "currency": "AUD",
            "minAmount": "",
            "layout": "inversed",
            "paymentOption": "equal",
            "promotionMonths": 50,
            "product": {
                "id": productObject.pid,
                "name": productObject.name,
                "category": productObject.category,
                "price": productObject.price,
                "sku": productObject.pid
        }};

        var curr = document.createElement("script");
        curr.type = "text/javascript";
        curr.async = true;
        curr.src = productObject.latitudeMerchantUrl;
        var scr = document.getElementsByTagName("script")[0];
        scr.parentNode.insertBefore(curr, scr);

        if (window.attachEvent) {
            window.attachEvent("onload", asyncLoad);
        } else {
            window.addEventListener("load", asyncLoad, false);
        }
    }
}

function uploadBNPLList() {
    const breakpoint = window.matchMedia('(max-width:991px)');
    if (breakpoint.matches) {
        $('.BNPL-PDP-mobile').removeClass('d-none');
        $('.BNPL-PDP-desktop').addClass('d-none');
        var latitudeContent = $('.list-BNPL-PDP .latitude-content-mobile');
        var productObject = {
            pid: latitudeContent.attr('data-product-id'),
            name: latitudeContent.attr('data-product-name'),
            category: latitudeContent.attr('data-product-category'),
            price: latitudeContent.attr('data-product-price'),
            latitudeMerchantId: latitudeContent.attr('data-latitude-merchant-id'),
            latitudeMerchantUrl: latitudeContent.attr('data-latitude-merchant-url'),
            latitudeContainer: 'latitude-banner-container-mobile',
        };
        if ($('.list-BNPL-PDP .latitude-content').text() === '') {
            asyncLoad(productObject);
        }
    } else {
        $('.BNPL-PDP-mobile').addClass('d-none');
        $('.BNPL-PDP-desktop').removeClass('d-none');
        var latitudeContent = $('.list-BNPL-PDP .latitude-content');
        var productObject = {
            pid: latitudeContent.attr('data-product-id'),
            name: latitudeContent.attr('data-product-name'),
            category: latitudeContent.attr('data-product-category'),
            price: latitudeContent.attr('data-product-price'),
            latitudeMerchantId: latitudeContent.attr('data-latitude-merchant-id'),
            latitudeMerchantUrl: latitudeContent.attr('data-latitude-merchant-url'),
            latitudeContainer: 'latitude-banner-container',
        };
        if ($('.list-BNPL-PDP .latitude-content').text() === '') {
            asyncLoad(productObject);
        }
    }
}

function loadSizeGuide() {
    $(document).on('click', ".link-guide-block .link-guide:not(.ignore-modal)", function (e) {
        e.preventDefault();
        $.spinner().start();
        const breakpoint = window.matchMedia('(max-width:991px)');
        if (breakpoint.matches) {
            openSizeGuideFlyout();
        } else {
            $("#sizeGuideModal").modal('show');
        }
        $.spinner().stop();
    });

    $('body').on('click', function (e) {
        if ($(e.target).is('.close-size-guide-flyout')) {
            closeSizeGuideFlyout();
        }
    });

    $("#sizeGuideModal").on('hide.bs.modal', function () {
        $("#sizeGuideModal .modal-content .modal-body .modal-body-inner").scrollTop(0);
    });
}

function openSizeGuideFlyout() {
	$('body').addClass('modal-open');
	$('body').addClass('disable-overflow');
	$('body > .modal-backdrop').remove();
	$('body').append('<div class="modal-backdrop fade show"></div>');
    $('.size-guide-flyout .size-guide-flyout-content').scrollTop(0);
	$('.size-guide-flyout').animate({
		'right': '0'
	}, 500);
}

function closeSizeGuideFlyout() {
    $('body').removeClass('modal-open');
    $('body').removeClass('disable-overflow');
    $('body > .modal-backdrop').remove();
    $('.size-guide-flyout').animate({
        'right': '-100%'
    }, 500);
}

module.exports = {
    ...parentDetail,
    attributeSelect: attributeSelect,
    selectAttribute: function() {
        $('#pdp-variation').change(function(e) {
            e.preventDefault();
            const quantity = $('[id*=quantity-]').val();
            const url = $(this)
                .children('option:selected')
                .data('url')
                .split('?')[0];
            $.spinner().start();
            window.location.href = `${url}?quantity=${quantity}`;
        });
    },

    selectSwatch: function() {
        $('.color-attribute').click(function(e) {
            if ($(this).hasClass('swatch-link')) {
                return;
            }
            e.preventDefault();
            var quantity = $('[id*=quantity-]').val();
            if (!quantity) {
                quantity = 1;
            }
            const url = $(this)
                .data('url')
                .split('?')[0];
            $.spinner().start();
            window.location.href = `${url}?quantity=${quantity}`;
        });

        $('.mattress-addon').on('click', '.swatches .swatch-link', function (e) {
            e.preventDefault();
            $(this).parent().parent().find('.swatch-circle').removeClass('selected');
            var a = $(this).data('swatch-id');
            $(this).parent().parent().find(".swatch-link[data-swatch-id='" + a + "']").find('.swatch-circle').addClass('selected');
            var pid = $(this).data('product-id');
            var pdpURL = $(this).data('pdpurl');
            var variantURL = "window.location=".concat(pdpURL);
            var $addonItem = $(this).parents('.addon-item');
            $addonItem.find('.product-tile-name-wrap').attr('onclick', variantURL);
            $addonItem.find('.product-tile-name-wrap').find('.card-title.product-tile-name').attr('href', pdpURL);
            $addonItem.find('.card-text.product-tile-subtitle').attr('href', pdpURL);
            $addonItem.find('.product-tile-price a').attr('href', pdpURL);
            $addonItem.find('.card-img a').attr('href', pdpURL);
            $addonItem.find('.card-img a').attr('data-gtm', $(this).attr('data-gtm'));
            $addonItem.find('.product-tile-name-wrap a').attr('data-gtm', $(this).attr('data-gtm'));
            $addonItem.attr('data-addon-gtm', $(this).attr('data-addon-gtm'));
            $(this).closest('.card-body').attr('data-variation-id', pid);
            $(this).closest('.addon-item').attr('data-addon-pid', pid);
            $(this).closest('.addon-item').find('.addon-checkbox').attr('id', pid);
            $(this).closest('.addon-item').find('.custom-control-label').attr('for', pid);
            const imgdata = $(this).data('image');
            if (imgdata && imgdata.url) {
                $(this).closest('.addon-item').find('.plp-image-link img').first().attr('src', imgdata.url);
                $(this).closest('.addon-item').find('.plp-image-link img').first().attr('data-src', imgdata.url);
            }
        });

        $('.custom-options').find('li').on('click', function(e) {
            if($(this).parent().hasClass('mattress-attribute')) {
              e.preventDefault();
              if ($(this).hasClass('selected')) { return; }
              var $productContainer = $(this).closest('.product-detail');
              attributeSelect($(this).attr('data-url'), $productContainer);
            }
        });

    },

    hideSpecsWhenNotAvailable: function() {
        if ($('.product-features > .row.col-12 .specification').length == 0) {
            $('.product-features').addClass('d-none');
        }
    },

    refreshPageOnModalCloses: function(){
        $('.set-store.closest-stores').on('click', function () {
            location.reload();
        })
    },

    EinsteinOnAddToCart: function(){
        $('body').on('DOMSubtreeModified', '.add-to-cart-messages', function(e){
            $('#add-to-cart-message').empty().html($('.add-to-basket-alert').html());
            $('#addToCartMessageModal').trigger('click');
            $('.add-to-basket-alert').remove();
            $("#addToCartMessage-modal-recommendations .slick-next.slick-arrow").trigger('click');
        });
        $('body').on('DOMNodeInserted', "#addToCartMessage-modal-recommendations .slick-next.slick-arrow", function(){
            $(this).trigger('click');
        });
    },

    uploadReviewList: function() {
        var topOffset = $('.header').outerHeight() + $('.trust-banner-carousel-container').outerHeight();

        var $reviewBlockContent = $('#pdpAccordion-collapseReviews');
        var $reviewBlock = $reviewBlockContent.parents('.accordion-item');
        var blockReady = false;
        var blockCoords = $reviewBlock.offset();

        blockCoords.top -= topOffset;

        $('.top-widget').on('mouseenter touchstart', function() {
            var $widget = $(this);
            var widgetCoords = $widget.offset();

            if(!blockReady) {
                //open the collapsed block in order to upload trustpilot widget
                $reviewBlockContent.collapse('show');
                blockReady = true;
                $reviewBlockContent.collapse('hide');
            }

            $(window).on('blur', function(e){
                $(window).off('blur');
                if(window.scrollY < widgetCoords.top) {
                    $reviewBlock.addClass('open');
                    $reviewBlock.children('.collapsible-title').attr('aria-expanded', true);
                    $reviewBlockContent.collapse('show');
                    $('html, body').animate({scrollTop: blockCoords.top}, 500, function() {
                        $widget.trigger('touchend');
                        window.top.focus();
                    });
                }
            });
        })
    },
    uploadBNPLList: uploadBNPLList,
    initScrollBrowser: function(){
        $(window).on('scroll', function () {
            var $backToTop = $('.back-to-top');
            $backToTop.toggleClass('d-none', $(window).scrollTop() < 100);
            if ($('body').find('#genesys-messenger').length) {
                $backToTop.css('bottom', 90);
            }
        });
    },
    loadSizeGuide: loadSizeGuide
};

$(window).on('resize', function() {
    uploadBNPLList();
});